.idea {
  padding: 5px;
  /* margin: 5px; */
  margin-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
}

.idea-icon {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-width: 80px;
  min-width: 70px;
}

.idea-icon h1 {
  font-size: 14px;
}

.idea-icon img {
  height: 40px;
  width: 40px;
}

.idea-content {
  margin-left: 10px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}

.idea-content-noidea {
  font-size: 16px;
  font-weight: 500;
}

.idea-content-title {
  font-size: 16px;
  font-weight: bold;
}

.idea-content-policy {
  font-size: 14px;
  display: flex;
  font-weight: 500;
  /* align-items: center; */
}

/* .fa-circle {
  font-size: 11px;
  margin-right: 5px;
}

.circle-red {
  color: red;
}

.circle-black {
  color: black;
}

.circle-blue {
  color: blue;
}

.circle-yellow {
  color: yellow;
}

.circle-green {
  color: green;
}

.circle-purple {
  color: purple;
} */

.idea-content-date {
  display: flex;
  font-size: 12px;
  color: grey;
  font-weight: 500;
}

.fa-angle-right {
  color: #0066cc;
  font-size: 20px;
  cursor: pointer;
  margin: 0px 0px 0px 10px;
}

@media screen and (max-width: 780px) {
  .fa-angle-right {
    color: #0066cc;
    font-size: 20px;
    cursor: pointer;
    margin: 0px 0px 0px 5px;
  }
}

.input-container {
  margin-right: 1em;
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 5px;
}

.left-inner-addon input {
  /* padding-left: 35px !important; */
  border: none;
}

.left-inner-addon i {
  /* position: absolute; */
  padding: 5px 3px 5px 8px;
  pointer-events: none;
}

/* .right-inner-addon {
  position: relative;
}
.right-inner-addon input {
  padding-right: 35px !important;
}
.right-inner-addon i {
  position: absolute;
  right: 0px;
  padding: 12px 12px;
  pointer-events: none;
} */

/* .left-and-right-inner-addon {
  position: relative;
}
.left-and-right-inner-addon input {
  padding-right: 35px !important;
  padding-left: 35px !important;
}
.left-and-right-inner-addon i.left {
  position: absolute;
  padding: 12px 12px;
  pointer-events: none;
}
.left-and-right-inner-addon i.right {
  position: absolute;
  right: 0px;
  padding: 12px 12px;
  pointer-events: none;
}

.right-inner-addon-b {
  position: relative;
}
.right-inner-addon-b input {
  padding-right: 35px !important;
}
.right-inner-addon-b i {
  position: absolute;
  right: 0px;
  padding: 9px 12px;
  pointer-events: none;
} */

input {
  width: 100%;
  padding: 5px 8px !important;
  margin: 0em !important;
  box-sizing: border-box;
}
