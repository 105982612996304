.row-class {
  font-size: 0.8rem;
  width: 2500px !important;
}

.row-class td {
  padding: 5px 7px !important;
}

.header-class {
  font-size: 0.8rem;
}

.header-class thead th {
  background-color: var(--color-primary) !important;
  color: #fff !important;
  font-weight: bold;
  padding: 5px 7px !important;
  word-break: keep-all;
  display: flex;
}

.justify-content-end {
  display: flex;
  justify-content: end;
  padding: 5px;
}

.justify-content-end span {
  font-size: 0.8rem;
}

.content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: blue;
}

.p-datatable-tbody tr:last-of-type {
  border-bottom: 1px solid var(--color-primary);
}

.row-class-report td {
  padding: 5px 7px !important;
}

.header-class-report {
  font-size: 0.8rem;
}

.header-class-report thead th {
  background-color: var(--color-primary) !important;
  color: #fff !important;
  font-weight: bold;
  padding: 5px 7px !important;
  word-break: keep-all;
}

.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
  font-size: 0.8rem;
  padding: 5px 8px;
}

.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: #495057;
  width: 2.357rem;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.p-dropdown-trigger {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  size: 0.8rem;
}

.p-dropdown .p-dropdown-label {
  background: transparent;
  border: 0 none;
  font-size: 0.8rem;
  padding: 5px 8px;
}

.p-inputtext {
  margin: 0px;
}

.p-column-filter-constraint .p-inputtext {
  margin: 0px;
  font-size: 0.8rem;
}

.berita-image {
  width: 150px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.berita-beranda-image {
  height: 300px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-buttonset .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-buttonset .p-button:not(:last-child) {
  border-right: 0;
}

.p-selectbutton .p-button.p-highlight {
  background: var(--color-primary);
  border-color: var(--color-primary);
  color: #ffffff;
}

.p-buttonset .p-button {
  margin: 0;
}

.p-selectbutton .p-button {
  background: #ffffff;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

.p-button {
  color: #ffffff;
  background: var(--color-primary);
  border: 1px solid var(--color-primary);
  padding: 5px 8px;
  font-size: 0.8rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  border-radius: 6px;
}
