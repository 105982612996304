* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

:root {
  --color-primary: #006699;
  --color-hover: #1888ff;
  --color-danger: crimson; /*  #bb2124; */
  --color-border: #e5e5e5;

  --font-size-text: 0.7rem;
  --font-size-hover: 0.8rem;
}

.main-container {
  padding: 10px;
}

.main-center-full {
  padding: 10px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main-center {
  padding: 10px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.card-header {
  display: flex;
  padding: 5px 10px;
  border: 1px solid var(--color-border);
  border-radius: 5px 5px 0 0;
  align-items: center;
  justify-content: space-between;
  color: var(--color-primary);
  background-color: var(--color-border);
}

.card-body {
  padding: 5px;
  border-left: 1px solid var(--color-border);
  border-right: 1px solid var(--color-border);
  color: var(--color-primary);
  height: 100%;
}

.without-footer {
  border-bottom: 1px solid var(--color-border);
  border-radius: 0 0 5px 5px;
}

.card-footer {
  display: flex;
  padding: 5px 10px;
  border-radius: 0 0 5px 5px;
  border: 1px solid var(--color-border);
  color: var(--color-primary);
}

.btn {
  padding: 5px 15px;
  border-radius: 15px;
  outline: none;
  border: none;
  font-size: var(--font-size-text);
  color: #fff;
  cursor: pointer;
  background-color: var(--color-primary);
}

.btn-primary {
  background-color: var(--color-primary);
}

.btn-danger {
  background-color: var(--color-danger);
}

.btn-draft {
  background-color: gray;
}

.btn-primary:hover {
  background-color: transparent;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  transition: all 0.3s ease-out;
  padding: 3px 13px;
}

.btn-danger:hover {
  background-color: transparent;
  color: var(--color-danger);
  border: 2px solid var(--color-danger);
  transition: all 0.3s ease-out;
  padding: 3px 13px;
}

.btn-draft:hover {
  background-color: transparent;
  color: gray;
  border: 2px solid gray;
  transition: all 0.3s ease-out;
  padding: 3px 13px;
}

.btn-group .btn {
  margin: 5px;
}

.form-group {
  display: flex;
  align-items: stretch;
}

.form-group div {
  flex-grow: 1;
  /* margin: 5px; */
}

@media screen and (max-width: 780px) {
  .form-group {
    display: flex;
  }

  .form-group div {
    flex-grow: 1;
    /* margin: 5px; */
  }
}

.control-label {
  font-size: var(--font-size-text);
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

.form-control {
  display: inline;
  width: 100%;
  padding: 5px 8px;
  margin-top: 2px;
  border: 1px solid silver;
  border-radius: 5px;
  font-size: var(--font-size-text);
  transition: 0.3s;
  outline: none;
}

.form-group .form-control:focus {
  border: 1px solid var(--color-primary);
}

.align-center {
  justify-content: center;
}

.align-right {
  justify-content: right;
}

.arrow-left {
  color: #0066cc;
  font-size: 20px;
  cursor: pointer;
}
